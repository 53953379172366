import * as React from 'react'
import * as css from './Slider.module.css'

export default function Slider({
  label,
  id,
  value,
  onInputChange,
  rangeProps,
  prefix,
  suffix,
  ...props
}) {
  return (
    <div className={css.root}>
      <label className={css.label} htmlFor={id}>
        {label}
      </label>

      <label htmlFor={id} className={css.inputLabel}>
        {prefix && <span>{prefix}</span>}

        <input
          id={id}
          className={css.input}
          type="number"
          value={value}
          onChange={onInputChange}
          {...props}
        />

        {suffix && <span>{suffix}</span>}
      </label>

      <input
        className={css.slider}
        type="range"
        value={value}
        onChange={onInputChange}
        {...props}
        {...rangeProps}
      />
    </div>
  )
}
