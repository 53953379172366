import * as React from 'react'
import * as css from './ManualInputs.module.css'
import { calculatorStore } from './store'
import Slider from './Slider'
import TermSelection from './TermSelection'

export default function ManualInputs() {
  const {
    deposit,
    setDeposit,
    annuityRate,
    setAnnuityRate,
    taxRate,
    setTaxRate,
  } = calculatorStore()

  return (
    <fieldset className={css.root}>
      <div className={css.inputs}>
        <Slider
          label="Deposit"
          id="deposit"
          value={deposit}
          onInputChange={(e) => setDeposit(e.target.value)}
          min={0}
          rangeProps={{
            step: 1000,
            max: 1_000_000,
          }}
          prefix="$"
        />
        <Slider
          label="Annuity Rate"
          id="annuity-rate"
          value={annuityRate}
          onInputChange={(e) => setAnnuityRate(e.target.value)}
          min={0}
          max={100}
          rangeProps={{
            step: 0.1,
            max: 20,
          }}
          suffix="%"
        />
        <Slider
          label="Effective Tax Rate"
          id="tax-rate"
          value={taxRate}
          onInputChange={(e) => setTaxRate(e.target.value)}
          min={0}
          max={100}
          suffix="%"
        />

        <TermSelection />
      </div>
    </fieldset>
  )
}
