import * as React from 'react'
import * as css from './ChartResults.module.css'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { calculatorStore, formatMoney } from './store'

Chart.register(CategoryScale)

export default function ChartResults({ money_saved_copy }) {
  const { deposit, annuityRate, taxRate, term } = calculatorStore()
  const [chartData, setChartData] = React.useState([0, 0])
  const [moneySaved, setMoneySaved] = React.useState(0)

  React.useEffect(() => {
    if (typeof document === 'undefined') return

    const TDAG = getData('TDAG_f') - getData('TDAG_i')
    const SGTA = sumSGTA()

    setChartData([TDAG, SGTA])
    setMoneySaved(TDAG - SGTA)

    return () => setChartData([0, 0])
  }, [deposit, annuityRate, taxRate, term])

  return (
    <article className={css.root}>
      <h2 className="h2">Same Gain With and Without Tax Deferral</h2>

      <div className={css.grid}>
        <div className={css.chart}>
          <Bar
            data={{
              labels: ['Tax-Deferred Annuity Gain', 'Same Gain Taxed Annually'],
              datasets: [
                { data: chartData, backgroundColor: ['#00609e', '#74cef5'] },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
            maintainAspectRatio={false}
            height={100}
          />
        </div>

        <dl className={css.savings}>
          <dt className="h3">{money_saved_copy}</dt>
          <dd className="h1">{formatMoney(moneySaved)}</dd>
        </dl>
      </div>
    </article>
  )
}

function getData(cellId) {
  return Number(document.getElementById(cellId)?.dataset.value ?? 0)
}

function sumSGTA() {
  const arr = Array.from(document.querySelectorAll('table .net-gain'))
  const sum =
    arr.reduce((acc, curr) => acc + Number(curr?.dataset?.value), 0) ?? 0

  return sum
}
