import * as React from 'react'
import * as css from './TaxEquivalentCalculator.module.css'
import { calculatorStore } from './store'
import ManualInputs from './ManualInputs'
import ChartResults from './ChartResults'
import TableResults from './TableResults'
import clsx from 'clsx'

export default function TaxEquivalentCalculator(props) {
  const {
    deposit,
    setDeposit,
    annuityRate,
    setAnnuityRate,
    taxRate,
    setTaxRate,
    term,
    setTerm,
  } = calculatorStore()

  React.useEffect(() => {
    const readUrlParams = () => {
      const params = new URLSearchParams(window.location.search)
      const depositParam = params.get('deposit')
      const annuityRateParam = params.get('annuityRate')
      const taxRateParam = params.get('taxRate')
      const termParam = params.get('term')

      if (depositParam) setDeposit(Number(depositParam))
      if (annuityRateParam) setAnnuityRate(Number(annuityRateParam))
      if (taxRateParam) setTaxRate(Number(taxRateParam))
      if (termParam) setTerm(Number(termParam))
    }

    readUrlParams()
  }, [])

  React.useEffect(() => {
    function writeUrlParams() {
      const params = new URLSearchParams()
      params.set('deposit', deposit)
      params.set('annuityRate', annuityRate)
      params.set('taxRate', taxRate)
      params.set('term', term)

      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params.toString()}`,
      )
    }

    writeUrlParams()
  }, [deposit, annuityRate, taxRate, term])

  return (
    <section className={clsx(css.root, 'padding container')}>
      <ManualInputs />
      <ChartResults {...props} />
      <TableResults />
    </section>
  )
}
