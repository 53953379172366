import * as React from 'react'
import * as css from './TableResults.module.css'
import { calculatorStore } from './store'
import AllTables from './AllTables'
import { IoChevronUpSharp } from 'react-icons/io5'

export default function TableResults() {
  const { showTables, setShowTables } = calculatorStore()

  return (
    <article>
      <details
        className={css.toggle}
        open={showTables}
        onToggle={(e) => setShowTables(e.target.open)}
      >
        <summary>
          {showTables ? 'Hide' : 'Show'} detailed breakdown
          <IoChevronUpSharp className={css.icon} />
        </summary>

        <AllTables />
      </details>
    </article>
  )
}
